@subscription-border: 2px solid @primary0;

.subscription {
    border-top: 2px solid @primary0;
    border-bottom: 2px solid @primary0;
    background: @bg0 url('blocks/subscription/subscription__bg1.jpg') 0 0 no-repeat;
    background-size: cover;

    &__wrapper {
        width: 840px;
        margin: 40px auto;
    }

    &__logo {
        text-align: left;
        margin: 20px auto -20px;
        width: 840px;
        box-sizing: border-box;
        padding-left: 20px;
    }

    &__header {
        padding: 14px;

        text-align: center;

        border-bottom: @subscription-border;
        background-color: rgba(20,19,37,.8);
    }

    &__title {
        font-size: 28px;
        font-weight: bold;

        margin: 0;

        text-align: center;

        color: @primary0;
    }

    &__central {
        position: relative;

        min-height: 500px;

        background: url('blocks/subscription/subscription__bg2.jpg') 0 0 no-repeat;
        background-size: cover;
    }

    &__text {
        position: absolute;
        top: 100px;
        left: 20px;

        width: 260px;
        padding: 15px;

        text-align: left;

        color: @primary0;
        background-color: rgba(20,19,37,.9);
    }

    &__text-title {
        margin: 0 0 10px;
        font-weight: bold;
        font-size: 20px;
        color: @primary1;
    }

    &__footer {
        padding: 20px 30px;

        text-align: center;

        border-top: @subscription-border;
        background-color: @bg0;
    }

    &__form {
        background-color: @primary0;
    }

    &__form-title {
        font-size: 20px;
        font-weight: normal;

        margin: 0 0 10px 0;

        text-align: left;

        color: @primary0;
    }

    &__security {
        font-size: 12px;

        position: relative;

        margin: 10px 0 0 20px;

        text-align: left;

        color: @primary3;
    }

    &__security::before {
        line-height: 16px;

        position: absolute;
        top: -1px;
        left: -20px;

        width: 16px;
        height: 16px;

        content: '';

        background: url('blocks/subscription/subscription__lock.png') 0 0 no-repeat;
        background-size: 100% 100%;
    }

    &__success {

    }

    &__greeting {
        padding: 20px;
        color: @bg0;
        //color: @primary0;
        background: @bg3;

    }

    @media all and (max-width: @pad-width) {
        display: none;
    }

}
