@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,700,&subset=latin,cyrillic-ext,cyrillic');
.content {
  background-color: #ececec;
}
.content .content__text {
  font-size: 19px;
  line-height: 1.7em;
  box-sizing: border-box;
  padding: 70px;
  background-color: #fff;
}
.content__link {
  text-decoration: none;
  color: #f26000;
}
.content__link:hover {
  text-decoration: underline;
}
.content__time {
  font-weight: bold;
}
.content__list-item {
  margin-bottom: 15px;
}
.content__sub-list {
  margin: 10px 0;
  list-style-type: disc;
}
.content__sub-list-item {
  margin-bottom: 10px;
}
.content__image {
  max-width: 100%;
  margin: 10px auto;
}
@media all and (max-width: 800px) {
  .content .content__text {
    padding: 20px 5%;
  }
}
.contents__list,
.contents__sub-list {
  list-style-type: none;
}
.contents__list {
  counter-reset: contents;
}
.contents__sub-list {
  counter-reset: sub-contents;
  margin-bottom: 1em;
}
.contents__list-item {
  position: relative;
  margin-bottom: 10px;
}
.contents__list-item_title {
  font-weight: bold;
}
.contents__list-item:before {
  position: absolute;
  left: -2em;
  counter-increment: contents;
  content: counter(contents) '. ';
}
.contents__sub-list-item {
  font-weight: normal;
  position: relative;
  margin-bottom: 15px;
}
.contents__sub-list-item:before {
  position: absolute;
  left: -2em;
  counter-increment: sub-contents;
  content: counter(contents) '.' counter(sub-contents) ' ';
}
@media all and (max-width: 550px) {
  .contents {
    line-height: 1.5em;
    font-size: 14px;
  }
  .contents__list {
    padding-left: 15px;
    margin-bottom: .5em;
  }
  .contents__sub-list {
    padding-left: 20px;
    margin-bottom: 0;
  }
  .contents__list-item {
    margin-bottom: 0;
  }
  .contents__list-item:before {
    left: -1.5em;
  }
  .contents__sub-list-item {
    margin-bottom: 0;
  }
  .contents__sub-list-item:before {
    left: -1.5em;
  }
}
.footer {
  overflow: hidden;
  padding: 20px 0;
  color: #fff;
  background-color: #333;
}
.footer__left {
  float: left;
  width: 30%;
}
.footer__logo {
  line-height: 13px;
  display: inline-block;
}
.footer__contacts {
  position: relative;
  float: right;
  width: 30%;
  text-align: right;
}
.footer__center {
  float: left;
  width: 35%;
  vertical-align: top;
}
.footer__phone {
  font-family: 'PT Sans Narrow', 'PT Sans', sans-serif;
  font-size: 26px;
  font-weight: 300;
  margin-bottom: 5px;
  text-decoration: none;
  color: #fff;
}
.footer__copy {
  font-size: 12px;
  margin-top: 10px;
  text-align: center;
  color: #cccccc;
}
.footer__link {
  text-decoration: none;
  color: #f26000;
}
.footer__link:hover {
  color: #ff9b59;
}
.footer__links {
  clear: both;
  overflow: hidden;
  text-align: center;
  font-size: 12px;
  margin: 10px auto 0;
}
.footer__links-item {
  color: #777;
  display: inline-block;
  margin: 5px 10px 0;
  text-decoration: none;
}
.footer__links-item:hover {
  color: #777;
  text-decoration: underline;
}
@media all and (max-width: 800px) {
  .footer__left,
  .footer__center,
  .footer__contacts {
    width: 100%;
    margin: 0 auto 20px;
    text-align: center;
  }
}
.header {
  box-sizing: border-box;
  width: 100%;
  min-width: 300px;
  padding: 70px 0 40px;
  text-align: center;
  color: #fff;
  background: #141325 url('blocks/header/header__bg.jpg') 50% 0 no-repeat;
  background-size: cover;
}
.header__announce {
  display: inline-block;
  margin: 0 2%;
  text-align: center;
  vertical-align: top;
}
.header__emblem {
  width: 100px;
  text-align: center;
}
.header__topline {
  overflow: hidden;
}
.header__logo {
  text-align: center;
}
.header__title {
  font-size: 41px;
  font-weight: 400;
  line-height: 52px;
  margin: 40px 0 5px;
  letter-spacing: 1px;
}
.header__title_small {
  font-size: 26px;
  line-height: 45px;
}
.header__title-name {
  font-size: 61px;
  font-weight: 700;
  color: #f26000;
}
.header__title-name_small {
  font-size: 41px;
}
.header__subtitle {
  font-size: 28px;
  font-weight: 200;
  margin: 5px 0 20px;
}
.header__date {
  font-size: 22px;
  font-weight: bold;
  float: right;
  width: 300px;
  padding: 5px 10px;
  text-decoration: none;
  color: #fff;
  border: 1px solid #3379b1;
}
.header__title-date {
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  margin: 5px auto 15px;
}
.header__date:hover {
  border-color: #f26000;
  background-color: #f26000;
}
.header__order {
  display: inline-block;
  padding: 10px 15px;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  background-color: #f26000;
}
.header__order:hover {
  background-color: #3379b1;
}
.header_type_march {
  padding-top: 30px;
}
@media (max-width: 1366px) {
  .header {
    background: #141325 url('blocks/header/header__bg_1366.jpg') 50% 0 no-repeat;
    background-size: cover;
  }
}
@media all and (max-width: 550px) {
  .header {
    padding: 30px 0 20px 0;
    background: #141325 url('blocks/header/header__bg2.jpg') 50% 0 no-repeat;
    background-size: cover;
  }
  .header__title {
    font-size: 30px;
    margin-top: 20px;
  }
  .header__title-name {
    font-size: 40px;
  }
}
.header-line {
  overflow: hidden;
  padding: 10px 0;
}
.header-line__logo {
  float: left;
}
.header-line__phone {
  font-family: 'PT Sans Narrow', 'PT Sans', sans-serif;
  font-size: 26px;
  font-weight: 300;
  line-height: 67px;
  float: right;
  text-decoration: none;
  color: #f26000;
}
.header-line__top {
  overflow: hidden;
  margin: 5px auto;
}
.header-line__bottom {
  padding: 25px 0;
  background-color: #f26000;
  margin-bottom: -10px;
}
.header-line__title {
  margin: 20px 0 20px 50px;
  color: #fff;
}
@media all and (max-width: 800px) {
  .header-line__phone {
    font-size: 22px;
  }
  .header-line__title {
    margin: 0;
    font-size: 28px;
  }
}
.logo {
  cursor: pointer;
}
.logo__img {
  width: 120px;
  height: 61px;
}
.logo__img_colored {
  width: 235px;
  height: 113px;
}
.logo__img_small {
  width: 150px;
  height: 71px;
}
@media all and (max-width: 550px) {
  .logo__img_colored {
    width: 150px;
    height: 71px;
  }
}
.march {
  overflow: hidden;
  padding: 60px 0 100px;
  height: 369px;
  text-align: center;
  font-family: Arial, sans-serif;
}
.march p {
  font-size: 500%;
  line-height: 100%;
  position: relative;
  z-index: 9;
  display: inline-block;
  margin-top: 100px;
  transform: rotate(90deg);
  box-shadow: 11px 0 26px -18px #000;
}
.march p b {
  font-weight: normal;
  color: #fff;
  text-shadow: #141325 0 0 1px;
}
.march p i {
  font-style: normal;
  color: #f26000;
}
.march p u {
  text-decoration: none;
  color: #3379b1;
  text-shadow: #fff 1px 1px 0, #fff -1px -1px 0, #fff 1px -1px 0, #fff -1px 1px 0;
}
.march .piso {
  position: relative;
  width: 700px;
  height: 680px;
  margin: 0 auto;
  perspective: 600px;
}
.march .piso footer {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotateX(45deg) translateY(-230px) translateZ(230px);
  border-radius: 100%;
  background: -webkit-radial-gradient(0% 50%, circle, rgba(96, 16, 48, 0) 9px, #613 10px, rgba(96, 16, 48, 0) 11px) 0 10px, -webkit-radial-gradient(100% 100%, rgba(96, 16, 48, 0) 9px, #613 10px, rgba(96, 16, 48, 0) 11px), #8a3;
  background-size: 20px 20px;
}
.march .nube {
  position: absolute;
  width: 200px;
  height: 60px;
  border-radius: 200px;
  background: #fff;
}
.march .nube:before,
.march .nube:after {
  position: absolute;
  top: -15px;
  left: 10px;
  width: 100px;
  height: 80px;
  content: '';
  transform: rotate(30deg);
  border-radius: 100px;
  background: #fff;
}
.march .nube:after {
  top: -55px;
  right: 15px;
  left: auto;
  width: 120px;
  height: 120px;
}
.march .nube.x1 {
  animation: animanubes 55s linear infinite;
}
.march .nube.x2 {
  left: 200px;
  transform: scale(0.6);
  animation: animanubes 65s linear infinite;
  opacity: .6;
}
.march .nube.x3 {
  top: 200px;
  left: -250px;
  transform: scale(0.8);
  animation: animanubes 40s linear infinite;
  opacity: .8;
}
.march .nube.x4 {
  top: 250px;
  left: 470px;
  transform: scale(0.75);
  animation: animanubes 58s linear infinite;
  opacity: .75;
}
@keyframes 'animanubes' {
  from {
    margin-left: 1000px;
  }
  to {
    margin-left: -1000px;
  }
}
.page {
  font-family: 'PT Sans', Arial, 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 17px;
  min-width: 320px;
  margin: 0;
  padding: 0;
  color: #000;
  background-color: #333;
}
.page__wrapper {
  box-sizing: border-box;
  min-width: 300px;
  max-width: 980px;
  margin: 0 auto;
  padding: 0 20px;
}
.page__title {
  font-family: 'PT Sans Narrow', 'PT Sans', sans-serif;
  font-size: 28px;
  margin: 0 0 20px;
  padding: 0 0 0 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #6a6a6a;
  border-left: 4px solid #3379b1;
}
.social {
  margin-bottom: 10px;
  text-align: center;
  vertical-align: top;
}
.social__icon {
  font-size: 0;
  line-height: 35px;
  display: inline-block;
  width: 35px;
  height: 35px;
  margin: 0 5px;
  text-align: center;
  border-radius: 100%;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 15px 15px;
}
.social__icon:hover {
  background-color: #f26000;
}
.social__icon_fb {
  background-image: url('blocks/social/social__icon_fb.svg');
}
.social__icon_vk {
  background-image: url('blocks/social/social__icon_vk.svg');
}
.social__icon_tw {
  background-image: url('blocks/social/social__icon_tw.svg');
}
.social__icon_ig {
  background-image: url('blocks/social/social__icon_ig.svg');
}
.social__icon_ytb {
  background-image: url('blocks/social/social__icon_ytb.svg');
}
.social__icon_msg {
  background-image: url('blocks/social/social__icon_msg.svg');
}
.social__icon_tlg {
  background-image: url('blocks/social/social__icon_tlg.svg');
}
.social__icon_med {
  background-image: url('blocks/social/social__icon_med.svg');
  background-size: 66%;
}
.soon {
  padding: 10px;
  margin-top: 20px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.4);
}
.soon__title {
  font-weight: normal;
}
.soon__link {
  text-decoration: none;
  color: #fff;
}
.soon__link:hover {
  color: #f26000;
}
.soon__item {
  font-size: 14px;
  display: inline-block;
  width: 150px;
  padding: 10px;
  vertical-align: top;
}
.soon__icon {
  height: 61px;
  margin: 0 auto;
  background: url('blocks/soon/soon__icon.png?rnd=2') no-repeat 0 0;
}
.soon__icon_bem {
  width: 76px;
}
.soon__icon_git {
  width: 125px;
  background-position: -83px 0;
}
.soon__icon_adv {
  width: 56px;
  background-position: -215px 0;
}
.soon__icon_node {
  width: 56px;
  background-position: -272px 0;
}
.soon__icon_react {
  width: 64px;
  background-position: -329px 0;
}
.soon__item-title {
  font-weight: normal;
}
@media all and (max-width: 550px) {
  .soon__item,
  .soon__link {
    width: 100%;
    box-sizing: border-box;
    padding: 0 0 25px;
  }
}
.subscription {
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
  background: #141325 url('blocks/subscription/subscription__bg1.jpg') 0 0 no-repeat;
  background-size: cover;
}
.subscription__wrapper {
  width: 840px;
  margin: 40px auto;
}
.subscription__logo {
  text-align: left;
  margin: 20px auto -20px;
  width: 840px;
  box-sizing: border-box;
  padding-left: 20px;
}
.subscription__header {
  padding: 14px;
  text-align: center;
  border-bottom: 2px solid #fff;
  background-color: rgba(20, 19, 37, 0.8);
}
.subscription__title {
  font-size: 28px;
  font-weight: bold;
  margin: 0;
  text-align: center;
  color: #fff;
}
.subscription__central {
  position: relative;
  min-height: 500px;
  background: url('blocks/subscription/subscription__bg2.jpg') 0 0 no-repeat;
  background-size: cover;
}
.subscription__text {
  position: absolute;
  top: 100px;
  left: 20px;
  width: 260px;
  padding: 15px;
  text-align: left;
  color: #fff;
  background-color: rgba(20, 19, 37, 0.9);
}
.subscription__text-title {
  margin: 0 0 10px;
  font-weight: bold;
  font-size: 20px;
  color: #f26000;
}
.subscription__footer {
  padding: 20px 30px;
  text-align: center;
  border-top: 2px solid #fff;
  background-color: #141325;
}
.subscription__form {
  background-color: #fff;
}
.subscription__form-title {
  font-size: 20px;
  font-weight: normal;
  margin: 0 0 10px 0;
  text-align: left;
  color: #fff;
}
.subscription__security {
  font-size: 12px;
  position: relative;
  margin: 10px 0 0 20px;
  text-align: left;
  color: #6a6a6a;
}
.subscription__security::before {
  line-height: 16px;
  position: absolute;
  top: -1px;
  left: -20px;
  width: 16px;
  height: 16px;
  content: '';
  background: url('blocks/subscription/subscription__lock.png') 0 0 no-repeat;
  background-size: 100% 100%;
}
.subscription__greeting {
  padding: 20px;
  color: #141325;
  background: #ececec;
}
@media all and (max-width: 800px) {
  .subscription {
    display: none;
  }
}
.weekly {
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
  background-color: #090b18;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%232a2f54' fill-opacity='0.4'%3E%3Cpath opacity='.5' d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3Cpath d='M6 5V0H5v5H0v1h5v94h1V6h94V5H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  text-align: center;
  margin: 0;
  padding: 50px;
}
.weekly__wrapper {
  background: #eaeae8 url('blocks/weekly/weekly__bg.jpg') bottom left no-repeat;
  background-size: 100% auto;
  max-width: 500px;
  margin: 0 auto;
  padding: 30px 70px 55px;
}
.weekly__title {
  font-family: 'PT Sans Narrow', 'PT Sans', sans-serif;
  margin: 20px 0;
  font-size: 40px;
  padding: 0;
}
.weekly__title-hl {
  color: #c86b06;
}
.weekly__sub-title {
  font-family: 'PT Sans', Arial, 'Helvetica Neue', Helvetica, sans-serif;
  font-weight: normal;
  margin: 20px 0;
  font-size: 20px;
  padding: 0;
  text-align: center;
}
.weekly__form {
  margin: 0;
  text-align: left;
}
.weekly__form_hidden {
  display: none;
}
.weekly__submit {
  font-size: 16px;
  position: relative;
  display: block;
  margin: 5px auto 30px;
  padding: 10px 15px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  border: 1px solid #fff;
  background-color: #f26000;
}
.weekly__submit:hover {
  background-color: #3379b1;
}
.weekly__submit[disabled] {
  color: #6a6a6a;
  background-color: #777;
}
.weekly__input {
  font-size: 18px;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 25px;
  padding: 6px;
  color: #333;
  border: none;
}
.weekly__success,
.weekly__already,
.weekly__error {
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.7);
  display: none;
}
.weekly__success_visible,
.weekly__already_visible,
.weekly__error_visible {
  display: block;
}
.weekly__error {
  background-color: rgba(193, 27, 5, 0.4);
}
@media all and (max-width: 550px) {
  .weekly {
    padding: 0;
  }
  .weekly__wrapper {
    padding: 20px 20px 50px;
  }
}
