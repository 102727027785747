@primary0: #fff;
@primary1: #f26000;
@primary2: #3379b1;
@primary3: #6a6a6a;
@primary4: #000;
@primary5: #d1e620;

@bg0: #141325;
@bg1: #fff;
@bg2: #333;

@bg3: #ececec;
@bg4: #eee;

@disabled: #777;
@text: #333;
@shaddow: #333;
@error: #900000;

@maxwidth: 980px;
@pad-width: 800px;
@minwidth: 300px;
@mobile-width: 550px;

@ff-title: 'PT Sans Narrow', 'PT Sans', sans-serif;
@ff-normal: 'PT Sans', Arial, 'Helvetica Neue', Helvetica, sans-serif;

.page {
    font-family: @ff-normal;
    font-size: 17px;

    min-width: @minwidth + 20;
    margin: 0;
    padding: 0;

    color: @primary4;
    background-color: @bg2;

    &__wrapper {
        box-sizing: border-box;
        min-width: @minwidth;
        max-width: @maxwidth;
        margin: 0 auto;
        padding: 0 20px;
    }

    &__title {
        font-family: @ff-title;
        font-size: 28px;

        margin: 0 0 20px;
        padding: 0 0 0 20px;

        letter-spacing: 1px;
        text-transform: uppercase;

        color: @primary3;
        border-left: 4px solid @primary2;
    }
}

.title() {
    margin: 0;
    padding: 0;
}

@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,700,&subset=latin,cyrillic-ext,cyrillic');
