.header {
    box-sizing: border-box;
    width: 100%;
    min-width: @minwidth;
    padding: 70px 0 40px;

    text-align: center;

    color: @primary0;
    background: @bg0 url('blocks/header/header__bg.jpg') 50% 0 no-repeat;
    //background: @bg0 url('blocks/header/header__bg2.jpg') 50% 0 no-repeat;
    background-size: cover;

    &__announce {
        display: inline-block;

        //width: 44%;
        margin: 0 2%;

        text-align: center;
        vertical-align: top;
    }

    &__emblem {
        width: 100px;

        text-align: center;
    }

    &__topline {
        overflow: hidden;
    }

    &__logo {
        text-align: center;
    }

    &__title {
        font-size: 41px;
        font-weight: 400;
        line-height: 52px;

        margin: 40px 0 5px;

        letter-spacing: 1px;
    }

    &__title_small {
        font-size: 26px;
        line-height: 45px;
    }

    &__title-name {
        font-size: 61px;
        font-weight: 700;

        color: @primary1;
    }

    &__title-name_small {
        font-size: 41px;
    }

    &__subtitle {
        font-size: 28px;
        font-weight: 200;

        margin: 5px 0 20px;
    }

    &__date {
        font-size: 22px;
        font-weight: bold;

        float: right;

        width: 300px;
        padding: 5px 10px;

        text-decoration: none;

        color: @primary0;
        border: 1px solid @primary2;
    }

    &__title-date {
        font-size: 18px;
        line-height: 18px;
        text-align: center;
        margin: 5px auto 15px;
    }

    &__date:hover {
        border-color: @primary1;
        background-color: @primary1;
    }

    &__order {
        display: inline-block;

        padding: 10px 15px;

        cursor: pointer;
        text-decoration: none;
        text-transform: uppercase;

        color: @primary0;
        background-color: @primary1;
    }

    &__order:hover {
        background-color: @primary2;
    }

    &_type_march {
        padding-top: 30px;
    }

    @media (max-width: 1366px) {
        background: @bg0 url('blocks/header/header__bg_1366.jpg') 50% 0 no-repeat;
        //background: @bg0 url('blocks/header/header__bg2.jpg') 50% 0 no-repeat;
        background-size: cover;
    }

    @media all and (max-width: @mobile-width) {
        padding: 30px 0 20px 0;
        background: @bg0 url('blocks/header/header__bg2.jpg') 50% 0 no-repeat;
        background-size: cover;

        &__title {
            font-size: 30px;
            margin-top: 20px;
        }
        &__title-name {
            font-size: 40px;
        }
    }

}
