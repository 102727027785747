.footer {
    overflow: hidden;

    padding: 20px 0;

    color: @primary0;
    background-color: @bg2;

    &__left {
        float: left;

        width: 30%;
    }

    &__logo {
        line-height: 13px;

        display: inline-block;
    }

    &__contacts {
        position: relative;

        float: right;

        width: 30%;

        text-align: right;
    }

    &__center {
        float: left;

        width: 35%;

        vertical-align: top;
    }

    &__phone {
        font-family: @ff-title;
        font-size: 26px;
        font-weight: 300;

        margin-bottom: 5px;

        text-decoration: none;

        color: @primary0;
    }

    &__copy {
        font-size: 12px;

        margin-top: 10px;

        text-align: center;

        color: darken(@primary0, 20%);
    }

    &__link {
        text-decoration: none;

        color: @primary1;
    }

    &__link:hover {
        color: lighten(@primary1, 20%);
    }

    &__links {
        clear: both;
        overflow: hidden;
        text-align: center;
        font-size: 12px;
        margin: 10px auto 0;

        &-item {
            color: @disabled;
            display: inline-block;
            margin: 5px 10px 0;
            text-decoration: none;

            &:hover {
                color: @disabled;
                text-decoration: underline;
            }
        }
    }

    @media all and (max-width: @pad-width) {
        &__left,
        &__center,
        &__contacts {
            width: 100%;
            margin: 0 auto 20px;

            text-align: center;
        }
    }
}
