.contents {
    &__list,
    &__sub-list {
        list-style-type: none;
    }

    &__list {
        counter-reset: contents;
    }

    &__sub-list {
        counter-reset: sub-contents;
        margin-bottom: 1em;
    }

    &__list-item {
        position: relative;
        margin-bottom: 10px;

        &_title {
            font-weight: bold;
        }

        &:before  {
            position: absolute;
            left: -2em ;

            counter-increment: contents;

            content: counter(contents) '. ';
        }
    }



    &__sub-list-item {
        font-weight: normal;

        position: relative;
        margin-bottom: 15px;

        &:before  {
            position: absolute;
            left: -2em ;
            counter-increment: sub-contents;

            content: counter(contents) '.' counter(sub-contents) ' ';
        }
    }

    @media all and (max-width: @mobile-width) {
        line-height: 1.5em;
        font-size: 14px;

        &__list {
            padding-left: 15px;
            margin-bottom: .5em;
        }

        &__sub-list {
            padding-left: 20px;
            margin-bottom: 0;
        }

        &__list-item {
            margin-bottom: 0;

            &:before {
                left: -1.5em;
            }
        }

        &__sub-list-item {
            margin-bottom: 0;

            &:before {
                left: -1.5em;
            }
        }
    }
}
