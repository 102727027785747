.march {
    overflow: hidden;

    padding: 60px 0 100px;
    height: 369px;

    text-align: center;
    font-family: Arial, sans-serif;

    //background: #06385f;
    //background: -moz-linear-gradient(top,  #06385f 0%, #46ccf2 100%);
    //background: -webkit-linear-gradient(top,  #06385f 0%,#46ccf2 100%);
    //background: linear-gradient(to bottom,  #06385f 0%,#46ccf2 100%);
    //filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#06385f', endColorstr='#46ccf2',GradientType=0 );

    p {
        font-size: 500%;
        line-height: 100%;

        position: relative;
        z-index: 9;

        display: inline-block;

        margin-top: 100px;

        transform: rotate(90deg);

        box-shadow: 11px 0 26px -18px #000;

        b {
            font-weight: normal;

            color: @primary0;
            text-shadow: @bg0 0 0 1px;
        }
        i {
            font-style: normal;

            color: @primary1;
        }
        u {
            text-decoration: none;

            color: @primary2;
            text-shadow: @primary0 1px 1px 0, @primary0 -1px -1px 0, @primary0 1px -1px 0, @primary0 -1px 1px 0;
        }
    }

    .piso {
        position: relative;

        width: 700px;
        height: 680px;
        margin: 0 auto;

        perspective: 600px;

        footer {
            position: absolute;

            width: 100%;
            height: 100%;

            transform: rotateX(45deg) translateY(-230px) translateZ(230px);

            border-radius: 100%;
            background: -webkit-radial-gradient(0% 50%,circle,rgba(96, 16, 48, 0) 9px, #613 10px,rgba(96, 16, 48, 0) 11px) 0 10px,
            -webkit-radial-gradient(100% 100%,rgba(96, 16, 48, 0) 9px, #613 10px,rgba(96, 16, 48, 0) 11px),#8a3;
            background-size: 20px 20px;
        }
    }

    .nube {
        position: absolute;

        width: 200px;
        height: 60px;

        border-radius: 200px;
        background: #fff;

        &:before,
        &:after {
            position: absolute;
            position: absolute;
            top: -15px;
            left: 10px;

            width: 100px;
            height: 80px;

            content: '';
            transform: rotate(30deg);

            border-radius: 100px;
            background: #fff;
        }
        &:after {
            top: -55px;
            right: 15px;
            left: auto;

            width: 120px;
            height: 120px;
        }
        &.x1 {
            animation: animanubes 55s linear infinite;
        }
        &.x2 {
            left: 200px;

            transform: scale(.6);
            animation: animanubes 65s linear infinite;

            opacity: .6;
        }
        &.x3 {
            top: 200px;
            left: -250px;

            transform: scale(.8);
            animation: animanubes 40s linear infinite;

            opacity: .8;
        }
        &.x4 {
            top: 250px;
            left: 470px;

            transform: scale(.75);
            animation: animanubes 58s linear infinite;

            opacity: .75;
        }
    }
}

@keyframes 'animanubes' {
    from {
        margin-left: 1000px;
    }
    to {
        margin-left: -1000px;
    }
}
