.social {
    margin-bottom: 10px;

    text-align: center;
    vertical-align: top;

    &__icon {
        font-size: 0;
        line-height: 35px;

        display: inline-block;

        width: 35px;
        height: 35px;
        margin: 0 5px;

        text-align: center;

        border-radius: 100%;
        background-color: @primary0;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: 15px 15px;
    }

    &__icon:hover {
        background-color: lighten(@primary1, 0%);
    }

    &__icon_fb {
        background-image: url('blocks/social/social__icon_fb.svg');
    }

    &__icon_vk {
        background-image: url('blocks/social/social__icon_vk.svg');
    }

    &__icon_tw {
        background-image: url('blocks/social/social__icon_tw.svg');
    }

    &__icon_ig {
        background-image: url('blocks/social/social__icon_ig.svg');
    }

    &__icon_ytb {
        background-image: url('blocks/social/social__icon_ytb.svg');
    }

    &__icon_msg {
        background-image: url('blocks/social/social__icon_msg.svg');
    }

    &__icon_tlg {
        background-image: url('blocks/social/social__icon_tlg.svg');
    }

    &__icon_med {
        background-image: url('blocks/social/social__icon_med.svg');
        background-size: 66%;
    }
}
