.content {
    background-color: @bg3;

    & &__text {
        font-size: 19px;
        line-height: 1.7em;

        box-sizing: border-box;
        padding: 70px;

        background-color: @bg1;
    }

    &__link {
        text-decoration: none;

        color: @primary1;

        &:hover {
            text-decoration: underline;
        }
    }

    &__time {
        font-weight: bold;
    }

    &__list-item {
        margin-bottom: 15px;
    }


    &__sub-list {
        margin: 10px 0;

        list-style-type: disc;
    }

    &__sub-list-item {
        margin-bottom: 10px;
    }

    &__image {
        max-width: 100%;
        margin: 10px auto;
    }

    @media all and (max-width: @pad-width) {
        & &__text {
            padding: 20px 5%;
        }
    }
}
