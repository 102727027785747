.soon {
    padding: 10px;
    margin-top: 20px;

    color: @primary0;
    background-color: rgba(0,0,0, 0.4);

    &__title {
        font-weight: normal;
    }

    &__link {
        text-decoration: none;
        color: @primary0;
    }

    &__link:hover {
        color: @primary1;
    }

    &__item {
        font-size: 14px;

        display: inline-block;

        width: 150px;
        padding: 10px;
        vertical-align: top;
    }

    &__icon {
        height: 61px;
        margin: 0 auto;

        background: url('blocks/soon/soon__icon.png?rnd=2') no-repeat 0 0;
    }

    &__icon_bem {
        width: 76px;
    }

    &__icon_git {
        width: 125px;

        background-position: -83px 0;
    }

    &__icon_adv {
        width: 56px;

        background-position: -215px 0;
    }

    &__icon_node {
        width: 56px;

        background-position: -272px 0;
    }

    &__icon_react {
        width: 64px;

        background-position: -329px 0;
    }

    &__item-title {
        font-weight: normal;
    }

    @media all and (max-width: @mobile-width) {
        &__item,
        &__link {
            width: 100%;
            box-sizing: border-box;
            padding: 0 0 25px;
        }
    }
}
