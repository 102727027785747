.logo {
    cursor: pointer;

    &__img {
        width: 120px;
        height: 61px;
    }

    &__img_colored {
        width: 235px;
        height: 113px;
    }

    &__img_small {
        width: 150px;
        height: 71px;
    }

    @media all and (max-width: @mobile-width) {
        &__img_colored {
            width: 150px;
            height: 71px;
        }
    }
}
