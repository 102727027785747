.weekly {
    border-top: 2px solid #fff;
    border-bottom: 2px solid #fff;

    background-color: #090b18;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%232a2f54' fill-opacity='0.4'%3E%3Cpath opacity='.5' d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3Cpath d='M6 5V0H5v5H0v1h5v94h1V6h94V5H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    text-align: center;
    margin: 0;
    padding: 50px;

    &__wrapper {
        background: #eaeae8 url('blocks/weekly/weekly__bg.jpg') bottom left no-repeat;
        background-size:  100% auto;
        max-width: 500px;
        margin: 0 auto;
        padding: 30px 70px 55px;
    }

    &__title {
        font-family: @ff-title;
        margin: 20px 0;
        font-size: 40px;
        padding: 0;

        &-hl {
            color: #c86b06;
        }
    }

    &__sub-title {
        font-family: @ff-normal;
        font-weight: normal;
        margin: 20px 0;
        font-size: 20px;
        padding: 0;
        text-align: center;
    }

    &__form {
        margin: 0;
        text-align: left;

        &_hidden {
            display: none;
        }
    }

    &__submit {
        font-size: 16px;

        position: relative;

        display: block;

        margin: 5px auto 30px;
        padding: 10px 15px;

        cursor: pointer;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;

        color: @primary0;
        border: 1px solid #fff;
        background-color: @primary1;
    }

    &__submit:hover {
        background-color: @primary2;
    }

    &__submit[disabled] {
        color: @primary3;
        background-color: @disabled;
    }

    &__input {
        font-size: 18px;

        box-sizing: border-box;
        width: 100%;
        margin-bottom: 25px;
        padding: 6px;

        color: @text;
        border: none;
    }

    &__success, &__already, &__error {
        padding: 20px;
        background-color: rgba(255,255,255,0.7);
        display: none;

        &_visible {
            display: block;
        }
    }

    &__error {
        background-color: rgba(193, 27, 5, 0.4);
    }

    @media all and (max-width: @mobile-width) {
        padding: 0;

        &__wrapper {
            padding: 20px 20px 50px;
        }
    }
}