.header-line {
    overflow: hidden;
    padding: 10px 0;

    &__logo {
        float: left;
    }

    &__phone {
        font-family: @ff-title;
        font-size: 26px;
        font-weight: 300;
        line-height: 67px;

        float: right;

        text-decoration: none;

        color: @primary1;
    }

    &__top {
        overflow: hidden;
        margin: 5px auto;
    }

    &__bottom {
        padding: 25px 0;

        background-color: @primary1;
        margin-bottom: -10px;
    }

    &__title {
        margin: 20px 0 20px 50px;

        color: @primary0;
    }

    @media all and (max-width: @pad-width) {
        &__phone {
            font-size: 22px;
        }

        &__title {
            margin: 0;
            font-size: 28px;
        }


    }
}
